import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography, Box } from '@mui/material';

const Section = ({ title, description, hasBackground, children }) => {
  return (
    <Box
      sx={{
        backgroundColor: hasBackground ? 'tertiary.main' : 'transparent',
        backgroundImage: !hasBackground ? `url('/assets/imgs/fondo.png')` : 'none',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        p: 4,
      }}
    >
      <Grid container spacing={3} direction="column">
        <Grid xs={12}>
          <Typography
            variant="h4"
            component="h4"
            sx={{ textAlign: { xs: 'center', sm: 'left' } }}
          >
            {title}
          </Typography>
        </Grid>

        <Grid xs={12}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Section;
