import React from 'react';
import { Box, Grid2, Typography } from '@mui/material';
import Section from './Section';

const SeccionPrimeraRifa = () => {
  return (
    <Section
      title="¿Cómo crear tu primera rifa?"
      hasBackground={true}
    >
      <Box sx={{textAlign: 'justify'}}>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          ¡Crear tu primera rifa con <strong>Mi Rifa App</strong> es muy fácil y divertido! Solo sigue estos sencillos pasos:
        </Typography>
        <ol>
          <li>
            <strong>
              Haz clic en el botón <img src="/assets/imgs/boton.png" style={{ width: '30px' }} alt="botón" />:
            </strong> 
            Este es el primer paso para comenzar tu rifa.
          </li>
          <li><strong>Selecciona una imagen:</strong> Toma una nueva foto o elige una ya existente de tu galería para representar tu rifa.</li>
          <li><strong>Define la cantidad de números:</strong> Decide cuántos números tendrá tu rifa. Si deseas más números, puedes optar por la suscripción premium, que te permitirá añadir tantos como necesites.</li>
          <li><strong>Agrega un título y descripción:</strong> Escribe un título atractivo y una descripción que explique de qué trata tu rifa.</li>
          <li><strong>Nombre de la lotería:</strong> Indica el nombre de la lotería que se encargará de realizar el sorteo.</li>
          <li><strong>Selecciona la fecha del sorteo:</strong> Elige una fecha para el evento de la rifa.</li>
          <li><strong>Establece el precio del boleto:</strong> Define el valor que tendrá cada boleto.</li>
          <li><strong>Agrega los premios:</strong> No olvides incluir uno o más premios para atraer a más participantes.</li>
        </ol>
        <Typography variant="body1" component="div">
          ¡Y eso es todo! Con estos pasos, tu rifa estará lista para ser compartida con tus amigos y familiares.
        </Typography>
        
        <Grid2 container spacing={5}>
          <Grid2 size={{xs: 12, md: 4}}>
            
          </Grid2>
        </Grid2>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexWrap: 'wrap',
            gap: 5, 
            mt: 2 
          }} 
        >
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="primera rifa 1" 
            src='/assets/imgs/primera-rifa-1.jpg' 
            loading='lazy'
          />
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="primera rifa 2" 
            src='/assets/imgs/primera-rifa-2.jpg' 
            loading='lazy'
          />
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionPrimeraRifa;
