import React from 'react';
import { Icon, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.tertiary.main,
  borderRadius: 10,
  padding: 10,
  margin: '10px 0',
  maxWidth: '100%',
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  textAlign: 'justify',
  fontFamily: 'Nexa',
  color: theme.palette.grey[700],
  flexShrink: 1,
}));

const Info = ({ texto }) => (
  <Container>
    <IconButton
      size="small"
      aria-label="Icono izquierdo"
      sx={{ padding: 0, marginRight: 1 }}
    >
      <Icon baseClassName="material-icons">info</Icon>
    </IconButton>
    <Text variant="body2">
      {texto}
    </Text>
  </Container>
);

export default Info;
