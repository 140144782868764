import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { desencriptar } from '../utilities/encriptado';
import { Typography, Card, CardMedia, CardContent, CircularProgress, Grid2, List, Box, Button } from '@mui/material';
import InfoListItem from '../components/InfoListItem';
import Tablero from '../components/Tablero';
import moment from 'moment';
import 'moment/locale/es';
import Info from '../components/Info';
moment.locale('es');

const MiRifa = () => {
  const { id } = useParams();
  const [dataRifa, setDataRifa] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchData = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}/rifas/mirifa/${encodeURIComponent(id)}`);
      const data = desencriptar(response.data);
      if (data && data.length > 0) {
        setDataRifa(data[0]);
      } else {
        setDataRifa(null);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la rifa");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    } else {
      setLoading(false);
    }
  }, [id]);

  const handleVolverInicio = () => {
    navigate('/');
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!dataRifa) {
    return (
      <Box
        sx={{
          backgroundImage: `url('/assets/imgs/fondo.png')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          px: { xs: 0, md: 5 },
          paddingTop: { xs: 10, md: 10 },
          paddingBottom: 3,
          height: '75vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h4" color="primary">
          Página no encontrada
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Por favor, vuelva a la página de inicio.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleVolverInicio}
          style={{ marginTop: 20 }}
        >
          Volver a la página de inicio
        </Button>
      </Box>
    );

  }

  return (
    <Box
      sx={{
        backgroundImage: `url('/assets/imgs/fondo.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        px: { xs: 0, md: 5 },
        paddingTop: { xs: 10, md: 3 },
        paddingBottom: 3
      }}
    >
      <Grid2 container spacing={{ xs: 1, md: 5 }}>
        <Grid2 size={{ xs: 12, md: 7 }}>
          <Card
            sx={{
              marginTop: { md: 10 },
              width: '100%',
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }}
          >
            <CardMedia
              sx={{
                height: { xs: 180, md: 220 },
                objectFit: 'cover',
              }}
              image={`${process.env.REACT_APP_URL_FILES}${dataRifa.rutaArchivo}`}
              title={dataRifa.titulo}
              loading='lazy'
            />
            <CardContent
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0)',
              }}
            >
              <Typography variant="h5" gutterBottom>
                {dataRifa.titulo}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {dataRifa.descripcion}
              </Typography>
              <List>
                <InfoListItem icono="confirmation_number" titulo="Lotería" descripcion={dataRifa.nombre_loteria} />
                <InfoListItem icono="event_available" titulo="Fecha del sorteo" descripcion={`${moment(dataRifa.fecha_sorteo).format('dddd, DD-MMM-YYYY hh:mm a')}`} />
                <InfoListItem icono="local_atm" titulo="Valor de la rifa" descripcion={`$${dataRifa.precio}`} />
                <InfoListItem icono="redeem" titulo="Premio" descripcion={dataRifa.premio} />
              </List>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 5 }} sx={{ padding: { xs: 1 }, marginTop: { md: 10 } }}>
          <Typography variant="h5" gutterBottom>Selecciona los números</Typography>
          <Info texto="Elige tus números preferidos en el tablero y haz clic en el botón 'Solicitar Reserva' que encontrarás más abajo." />
          <Tablero rifa={dataRifa} />
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default MiRifa;
