import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import Section from '../components/Section';

const SeccionVenderBoleto = () => {
  return (
    <Section
      title="¿Cómo vender un boleto?"
      hasBackground={false}
    >
      <Box sx={{textAlign: 'justify'}}>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          Vender un boleto en <strong>Mi Rifa App</strong> es un proceso sencillo. Hay dos formas de hacerlo:
        </Typography>

        <Typography variant="h6" component="div" sx={{ mt: 3 }}>
          Opción 1: Desde el Tablero
        </Typography>
        <List sx={{ mb: 2 }}>
          <ListItem>
            <ListItemText primary="Ingresa a la app y selecciona la rifa previamente creada." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ve a la pestaña llamada tablero y selecciona los números que deseas vender." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Se habilitará un formulario donde podrás seleccionar el estado del boleto (Reservado o Pagado)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ingresa los datos del comprador: nombre, código de área del país, número telefónico, y correo electrónico (opcional)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Presiona el botón Actualizar. Esto enviará una notificación mediante WhatsApp al comprador. Si has ingresado el correo electrónico, también recibirá una notificación por esta vía." />
          </ListItem>
        </List>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexWrap: 'wrap',
            gap: 5, 
            mt: 2 
          }} 
        >
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="vender boleto 1" 
            src='/assets/imgs/vender-1.jpg' 
            loading='lazy'
          />
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="vender boleto 2" 
            src='/assets/imgs/vender-2.jpg' 
            loading='lazy'
          />
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="vender boleto 3" 
            src='/assets/imgs/vender-3.jpg' 
            loading='lazy'
          />
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="vender boleto 4" 
            src='/assets/imgs/vender-4.jpg' 
            loading='lazy'
          />
        </Box>

        <Typography variant="h6" component="div" sx={{ mt: 4 }}>
          Opción 2: Desde el Listado de Ventas
        </Typography>
        <List sx={{ mb: 2 }}>
          <ListItem>
            <ListItemText primary="Ingresa a la app y selecciona la rifa previamente creada." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ve a la pestaña llamada ventas para ver el listado de todos los boletos." />
          </ListItem>
          <ListItem>
            <ListItemText primary="A la derecha de cada boleto, encontrarás un botón con 3 puntos (...). Al presionarlo, se abrirá un formulario." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Selecciona el estado del boleto (Reservado o Pagado), e indica si es el número ganador." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Ingresa los datos del comprador: nombre, código de área del país, número telefónico, y correo electrónico (opcional)." />
          </ListItem>
          <ListItem>
            <ListItemText primary="Al presionar el botón Actualizar, se enviará una notificación vía WhatsApp al comprador. Si has ingresado el correo electrónico, también recibirá una notificación por esta vía." />
          </ListItem>
        </List>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            flexWrap: 'wrap',
            gap: 5, 
            mt: 2 
          }} 
        >
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="vender boleto 1" 
            src='/assets/imgs/vender-1.jpg' 
            loading='lazy'
          />
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="vender boleto 2" 
            src='/assets/imgs/vender-10.jpg' 
            loading='lazy'
          />
          <Box 
            component="img" 
            sx={{ 
              maxWidth: '250px', 
              height: 'auto'
            }} 
            alt="vender boleto 3" 
            src='/assets/imgs/vender-11.jpg' 
            loading='lazy'
          />
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionVenderBoleto;
