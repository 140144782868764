import React, { useState } from 'react';
import { Grid2, Typography, Box, Button } from '@mui/material';

const Tablero = ({ rifa }) => {
  const [numerosSeleccionados, setNumerosSeleccionados] = useState([]);

  const handleSeleccionarNumero = (numero) => {
    setNumerosSeleccionados((prevSeleccionados) => {
      if (prevSeleccionados.includes(numero)) {
        return prevSeleccionados.filter((num) => num !== numero);
      } else {
        return [...prevSeleccionados, numero];
      }
    });
  };

  const handleSolicitarReserva = () => {
    const numeros = numerosSeleccionados.join(', ');
    const mensaje = `¡Hola! Me gustaría reservar los siguientes números en la rifa "${rifa.titulo}": ${numeros}. ¿Están disponibles?`;
    const telefono = `+${rifa.codigo_pais}${rifa.telefono_movil}`
    const url = `https://wa.me/${telefono}?text=${encodeURIComponent(mensaje)}`;
    
    window.open(url, '_blank');
  };

  return (
    <Box>
      <Grid2 container spacing={0} justifyContent="center">
        {rifa.numeros.map((item, index) => {
          let colorTexto = 'white';
          let backgroundColor = 'transparent';
          let emoji = '';

          switch (item.estado) {
            case '1': // Disponibles
              colorTexto = rifa.color_texto_disponibles;
              backgroundColor = numerosSeleccionados.includes(item.numero)
                ? 'red' // Color rojo si está seleccionado
                : rifa.color_fondo_disponibles;
              break;
            case '2': // Reservadas
              colorTexto = rifa.color_texto_reservadas;
              backgroundColor = rifa.color_fondo_reservadas;
              emoji = rifa.emoji_reservadas;
              break;
            case '3': // Pagadas
              colorTexto = rifa.color_texto_pagadas;
              backgroundColor = rifa.color_fondo_pagadas;
              emoji = rifa.emoji_pagadas;
              break;
            default:
              break;
          }

          return (
            <Grid2
              key={item.id}
              size={1.2}
              style={{ width: `${100 / 10}%` }}
              onClick={() => {
                if (item.estado === '1') {
                  handleSeleccionarNumero(item.numero);
                }
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderWidth: 0.8,
                  borderStyle: 'solid',
                  borderColor: 'white',
                  backgroundColor,
                  color: colorTexto,
                  aspectRatio: '1 / 1',
                  margin: 0,
                  minWidth: 35,
                  cursor: item.estado === '1' ? 'pointer' : 'default',
                }}
              >
                {item.estado === '1' ? (
                  <Typography sx={{ fontSize: 18, textAlign: 'center' }}>
                    {item.numero}
                  </Typography>
                ) : item.estado === '2' || item.estado === '3' ? (
                  <Typography sx={{ fontSize: 18, textAlign: 'center' }}>
                    {emoji}
                  </Typography>
                ) : null}
              </Box>
            </Grid2>
          );
        })}
      </Grid2>

      {numerosSeleccionados.length > 0 && (
        <Box mt={2} textAlign="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSolicitarReserva}
            style={{width: '100%'}}
          >
            Solicitar Reserva
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Tablero;
