import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2d3484',
    },
    secondary: {
      main: '#f15b28',
    },
    tertiary: {
      main: '#f15a281f'
    },
    cuaternary: {
      main: '#6861a5'
    }
  },
  typography: {
    fontFamily: 'Nexa, Arial, sans-serif',
    fontWeightRegular: 400,
    fontWeightBold: 700,
    fontWeightLight: 300,
    fontWeightMedium: 500,
  },
});

export default theme;
