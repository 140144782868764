import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import Section from './Section';

const SeccionEstadisticas = () => {
  return (
    <Section
      title="¿Cómo ver las estadísticas de una rifa?"
      hasBackground={false}
    >
      <Box sx={{ textAlign: 'justify' }}>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          En <strong>Mi Rifa App</strong>, puedes acceder fácilmente a las estadísticas de tus rifas. Solo sigue estos pasos:
        </Typography>

        <List>
          <ListItem>
            <ListItemText primary="1. Ingresa a la app y selecciona la rifa para la cual deseas ver las estadísticas." />
          </ListItem>
          <ListItem>
            <ListItemText primary="2. Ve a la pestaña llamada 'estadísticas'." />
          </ListItem>
          <ListItem>
            <ListItemText primary="3. Verás dos tipos de estadísticas disponibles:" />
          </ListItem>
        </List>

        <Typography variant="body1" component="div" sx={{ mb: 2, ml: 4 }}>
          <strong>Estadísticas de la Rifa:</strong> Muestra una tabla con la cantidad de boletos por estado (reservado, pagado, etc.) y un gráfico que representa esta información visualmente.
        </Typography>

        <Typography variant="body1" component="div" sx={{ mb: 2, ml: 4 }}>
          <strong>Estadísticas Financieras:</strong> Te permite ver el total recaudado, cuántos boletos están reservados (pendientes por cobrar) y cuántos están pagados (total recaudado).
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            gap: 5,
            mt: 2
          }}
        >
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Estadística 1"
            src='/assets/imgs/vender-1.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Estadística 2"
            src='/assets/imgs/estadisticas-1.jpg'
            loading='lazy'
          />
          <Box
            component="img"
            sx={{
              maxWidth: '250px',
              height: 'auto'
            }}
            alt="Estadística 3"
            src='/assets/imgs/estadisticas-2.jpg'
            loading='lazy'
          />
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionEstadisticas;
