import React from 'react';
import { Box, Typography, Grid2 } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 5, px: { xs: 1, md: 0 } }}>
      <Grid2 container spacing={0}>
        <Grid2 size={{ xs: 12, md: 4 }} sx={{ textAlign: 'center' }}>
          <Typography variant="body2">Síguenos en:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, justifyContent: 'center' }}>
            <a href="https://www.facebook.com/rauhtech" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none', marginRight: '20px' }}>
              <img src="/assets/imgs/facebook.png" alt="Facebook" style={{ height: '64px' }} />
            </a>
            <a href="https://www.instagram.com/rauhtech" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none', marginRight: '20px' }}>
              <img src="/assets/imgs/instagram.png" alt="Instagram" style={{ height: '64px' }} />
            </a>
            <a href="https://www.tiktok.com/@rauhtech" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
              <img src="/assets/imgs/tiktok.png" alt="TikTok" style={{ height: '64px' }} />
            </a>
          </Box>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 4 }} sx={{ textAlign: 'center' }}>
          <Typography variant="body2">Descárgala en las tiendas oficiales</Typography>
          <Grid2 container spacing={0} sx={{ mt: 1 }}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <a href="https://play.google.com/store/apps/details?id=com.rauhtech.rauhapp&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">
                <img src="/assets/imgs/play-store.png" alt="Descargar en Google Play" style={{ height: '70px' }} />
              </a>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <a href="/" rel="noopener noreferrer">
                <img src="/assets/imgs/app-store.png" alt="Descargar en App Store" style={{ height: '70px' }} />
              </a>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 4 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2">
              Desarrollado por RAUH TECH Ltda
            </Typography>
            <Box sx={{ mt: 1 }}>
              <a href="https://www.rauhtech.com" target="_blank" rel="noopener noreferrer" >
                <img src="/assets/imgs/logo_rauh.png" alt="RAUH TECH Ltda" style={{ height: '48px' }} />
              </a>
            </Box>
          </Box>
        </Grid2>
      </Grid2>
    </Box >
  );
};

export default Footer;
