import React from 'react';
import { Box, Toolbar } from '@mui/material';
import Carrusel from '../components/Carrusel';
import SeccionQueEs from '../components/SeccionQueEs';
import SeccionDescarga from '../components/SeccionDescarga';
import SeccionRegistro from '../components/SeccionRegistro';
import SeccionPrimeraRifa from '../components/SeccionPrimeraRifa';
import SeccionCambiarEstado from '../components/SeccionCambiarEstado';
import SeccionVenderBoleto from '../components/SeccionVenderBoleto';
import SeccionEstadisticas from '../components/SeccionEstadisticas';
import SeccionPersonalizar from '../components/SeccionPersonalizar';

const Home = () => {
  return (
    <Box component="main">
      <Toolbar />
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Carrusel />
      </Box>
      <SeccionQueEs />
      <SeccionDescarga />
      <SeccionRegistro />
      <SeccionPrimeraRifa />
      <SeccionVenderBoleto />
      <SeccionCambiarEstado />
      <SeccionEstadisticas />
      <SeccionPersonalizar />
    </Box>
  );
};

export default Home;
