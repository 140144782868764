import React from 'react';
import { Box, Grid2, Link, List, ListItem, Toolbar, Typography } from '@mui/material';

const TerminosCondiciones = () => {
  return (
    <Box sx={{ padding: { xs: 3, md: 6 } }}>
      <Toolbar />
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Typography variant="h4" sx={{ marginTop: 1, textAlign: 'center' }}>
            TÉRMINOS Y CONDICIONES DE USO DE MI RIFA APP
          </Typography>

          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Última actualización: 2024-09-19
          </Typography>

          <Typography sx={{ mt: 3, textAlign: 'justify' }}>
            Bienvenido a <strong>Mi Rifa App</strong>. Estos Términos y Condiciones (en adelante, los "Términos") regulan el acceso y uso de la aplicación <strong>Mi Rifa App</strong>, propiedad de <strong>RAUH Technologies Limitada</strong>. Al descargar y utilizar nuestra aplicación, usted acepta cumplir con estos Términos. Si no está de acuerdo con alguna de las disposiciones aquí mencionadas, le recomendamos no utilizar la aplicación.
          </Typography>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            1. Objeto de la aplicación
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            <strong>Mi Rifa App</strong> es una plataforma destinada exclusivamente a la gestión de rifas organizadas por los propios usuarios. A través de la aplicación, los usuarios pueden:
          </Typography>

          <List sx={{ listStyleType: 'disc', ml: 6 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Crear rifas.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Registrar la cantidad de boletas disponibles y asignar cada boleta a un comprador.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Marcar el estado de las boletas (pagadas, reservadas, etc.).</ListItem>
          </List>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            <strong>Mi Rifa App</strong> no se involucra en la venta, promoción, organización, recaudación de dinero o entrega de premios relacionados con las rifas creadas en la plataforma. Todas las responsabilidades relacionadas con la organización de la rifa, la venta de boletas, la recaudación de fondos y la entrega de premios recaen exclusivamente en el usuario que crea y gestiona la rifa.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            2. Responsabilidad del usuario
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Al crear y gestionar rifas a través de <strong>Mi Rifa App</strong>, el usuario asume la total responsabilidad sobre las actividades relacionadas con la rifa, incluyendo, pero no limitándose a:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3, textAlign: 'justify' }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>La venta de boletas.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>La recaudación de dinero.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>La distribución de premios.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>El cumplimiento de las leyes aplicables a las rifas en su jurisdicción.</ListItem>
          </List>

          <Typography sx={{ mt: 1, ml: 3, textAlign: 'justify' }}>
            <strong>RAUH Technologies Limitada</strong> no se hace responsable de ninguna disputa, reclamación, fraude o cualquier otra cuestión que surja entre los usuarios de la aplicación o entre un usuario y los participantes de su rifa.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            3. Suscripciones
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            <strong>Mi Rifa App</strong> ofrece servicios adicionales a través de suscripciones, las cuales proporcionan características avanzadas en la plataforma. Existen dos planes de suscripción:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Plan Mensual:</strong> El Plan Mensual permite acceso a características avanzadas de la aplicación por un período de 30 días, renovándose automáticamente al finalizar el plazo, salvo que el usuario cancele la suscripción antes del próximo ciclo de facturación.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Plan Anual:</strong> El Plan Anual ofrece los mismos beneficios que el plan mensual, pero con una duración de 12 meses. Esta suscripción también se renueva automáticamente, a menos que el usuario decida cancelarla con anterioridad.</ListItem>
          </List>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            3.1. Términos de suscripción
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Pago:</strong> El pago de la suscripción se realiza a través de la plataforma de pagos de Google Play o Apple App Store, dependiendo del dispositivo del usuario. Al suscribirse, el usuario autoriza el cobro automático del plan seleccionado en cada ciclo de facturación.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Cancelación:</strong> El usuario puede cancelar su suscripción en cualquier momento a través de la configuración de su cuenta en Google Play o Apple App Store. La cancelación surtirá efecto al final del período de facturación actual, y no se otorgarán reembolsos por los días no utilizados.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Cambios en los precios: RAUH Technologies Limitada</strong> se reserva el derecho de modificar los precios de las suscripciones. En caso de que se realice un cambio en los precios, se notificará al usuario con antelación razonable y el nuevo precio se aplicará al siguiente ciclo de facturación.</ListItem>
          </List>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            4. Condiciones de uso
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Al utilizar <strong>Mi Rifa App</strong>, el usuario se compromete a:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Utilizar la aplicación conforme a la legislación vigente y a no emplearla para fines ilegales o no autorizados.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>No realizar rifas que involucren productos o servicios ilegales, apuestas no autorizadas o cualquier otra actividad prohibida por la ley.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>No compartir contenido difamatorio, ofensivo, engañoso o que infrinja derechos de terceros.</ListItem>
          </List>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            5. Limitación de responsabilidad
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            <strong>RAUH Technologies Limitada</strong> no garantiza la disponibilidad continua y sin interrupciones de la aplicación. La empresa no se hace responsable de los daños directos o indirectos derivados del uso o la imposibilidad de uso de la plataforma, incluyendo, pero no limitándose a, errores técnicos, pérdida de datos o accesos no autorizados.
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Asimismo, <strong>RAUH Technologies Limitada</strong> no es responsable por el cumplimiento de las obligaciones derivadas de la rifa creada por el usuario, ni garantiza la veracidad o exactitud de la información introducida por los usuarios en la plataforma.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            6. Propiedad intelectual
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Todos los derechos de propiedad intelectual e industrial sobre la aplicación <strong>Mi Rifa App</strong>, así como sus contenidos, diseño, software y marcas, pertenecen a <strong>RAUH Technologies Limitada</strong> o a terceros que han autorizado su uso. Queda prohibida la reproducción, distribución o modificación no autorizada de cualquier elemento protegido por derechos de propiedad intelectual.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            7. Protección de datos personales
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            El tratamiento de los datos personales de los usuarios se regirá por lo dispuesto en nuestra Política de Privacidad, que puede revisar a detalle en
            <Link href="https://www.rauhtech.com/politicas-privacidad"> <strong>https://www.rauhtech.com/politicas-privacidad</strong></Link>, la cual debe ser aceptada para el uso de la aplicación.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            8. Modificación de los Términos y Condiciones
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            <strong>RAUH Technologies Limitada</strong> se reserva el derecho a modificar los presentes Términos en cualquier momento. Los cambios serán notificados a los usuarios a través de la aplicación o por otros medios de comunicación disponibles. El uso continuado de la aplicación después de la notificación de los cambios implicará la aceptación de los nuevos Términos.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            9. Legislación aplicable y jurisdicción
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Estos Términos se regirán e interpretarán de acuerdo con las leyes de Colombia, sin perjuicio de las disposiciones obligatorias aplicables en el lugar de residencia del usuario. Cualquier controversia derivada de la interpretación o ejecución de los presentes Términos será sometida a la jurisdicción de los tribunales competentes en la ciudad de Bogotá.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            10. Contacto
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Para cualquier consulta relacionada con estos Términos y Condiciones, puede contactar con nosotros en:
          </Typography>

          <List sx={{ listStyleType: 'disc', ml: 6 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>RAUH Technologies Limitada</strong></ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Dirección: Carrera 14 #98-73 Oficina 101. Bogotá – 110221. Colombia.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Correo electrónico de contacto:
              <Link href="mailto:administration@rauhtech.com"> <strong>administration@rauhtech.com</strong></Link>
            </ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Sitio web: <Link href="https://www.rauhtech.com"><strong>https://www.rauhtech.com</strong></Link></ListItem>
          </List>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default TerminosCondiciones;
