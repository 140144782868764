import React from 'react';
import { Box, Typography } from '@mui/material';
import Section from './Section';

const SeccionRegistro = () => {
  return (
    <Section
      title="¿Cómo registrarse?"
      hasBackground={false}
    >
      <Box sx={{textAlign: 'justify'}}>
        <Typography variant="body1" component="div" sx={{ mb: 2 }}>
          Registrarte es un proceso rápido y sencillo. Solo sigue estos pasos para empezar a disfrutar de todas las funcionalidades que te ofrecemos:
        </Typography>
        <ol>
          <li><strong>Nombre y Apellido:</strong> Introduce tu nombre y apellido.</li>
          <li><strong>Email:</strong> Proporciona un correo electrónico válido.</li>
          <li><strong>Código de Área:</strong> Selecciona el código de área de tu país.</li>
          <li><strong>Número de Teléfono:</strong> Escribe tu número de teléfono.</li>
          <li><strong>Contraseña:</strong> Crea una contraseña segura.</li>
          <li><strong>Repetir Contraseña:</strong> Vuelve a escribir tu contraseña.</li>
          <li><strong>Aceptar Términos:</strong> Acepta nuestras políticas de privacidad y términos de uso.</li>
        </ol>
        <Typography variant="body1" component="div">
          Así de simple es registrarte. Una vez que completes estos pasos, estarás listo para disfrutar de todas las funcionalidades de <strong>Mi Rifa App.</strong>
        </Typography>
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            mt: 2 
          }} 
        >
          <Box 
            component="img" 
            sx={{ maxWidth: '250px' }} 
            alt="registro" 
            src='/assets/imgs/registro.png' 
            loading='lazy'
          />
        </Box>
      </Box>
    </Section>
  );
};

export default SeccionRegistro;
