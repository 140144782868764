import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Button, Icon, ListItemButton } from '@mui/material';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Link
        to="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          color: 'inherit',
          justifyContent: 'center',
          padding: '10px 0'
        }}
      >
        <img
          src={`/assets/imgs/logo-color.png`}
          alt="Mi Rifa App Logo"
          style={{ height: '60px' }}
          loading='lazy'
        />
      </Link>
      <Divider />
      <List>
        <ListItemButton component={Link} to="/" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <ListItemText primary="Inicio" />
        </ListItemButton >
        <ListItemButton component={Link} to="/politicas-privacidad" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <ListItemText primary="Políticas de Privacidad" />
        </ListItemButton>
        <ListItemButton component={Link} to="/terminos-y-condiciones" onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
          <ListItemText primary="Términos y Condiciones" />
        </ListItemButton >
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Icon baseClassName="material-icons">menu</Icon>
          </IconButton>
          <Box component={Link} to="/" sx={{ flexGrow: 1, p: 1 }}>
            <img
              src={`/assets/imgs/logo.png`}
              alt="Mi Rifa App Logo"
              style={{ height: '60px' }}
              loading='lazy'
            />
          </Box>

          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Button color="inherit" component={Link} to="/" sx={{ textTransform: 'none' }}>Inicio</Button>
            <Button color="inherit" component={Link} to="/politicas-privacidad" sx={{ textTransform: 'none' }}>Políticas de Privacidad</Button>
            <Button color="inherit" component={Link} to="/terminos-y-condiciones" sx={{ textTransform: 'none' }}>Términos y Condiciones</Button>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;