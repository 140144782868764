import React from 'react';
import { Box, Grid2, Link, List, ListItem, Toolbar, Typography } from '@mui/material';

const PoliticasPrivacidad = () => {
  return (
    <Box sx={{ padding: { xs: 3, md: 6 } }}>
      <Toolbar />
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Typography variant="h4" sx={{ marginTop: 1, textAlign: 'center' }}>
            POLÍTICA DE PRIVACIDAD DE MI RIFA APP
          </Typography>

          <Typography variant="h6" sx={{ textAlign: 'center' }}>
            Última actualización: 2024-09-19
          </Typography>

          <Typography sx={{ mt: 3, textAlign: 'justify' }}>
            En <strong>RAUH Technologies Limitada</strong>, respetamos su privacidad y nos comprometemos a proteger la información personal que nos proporciona a través de nuestra aplicación, <strong>Mi Rifa App</strong>. Esta Política de Privacidad describe cómo recopilamos, utilizamos, compartimos y protegemos sus datos personales, de acuerdo con las normativas aplicables sobre protección de datos y privacidad, incluyendo el Reglamento General de Protección de Datos (RGPD) y otras normativas locales.
          </Typography>

          <Typography sx={{ mt: 3, mb: 5, textAlign: 'justify' }}>
            Al utilizar nuestra aplicación, usted acepta las prácticas descritas en esta Política de Privacidad. Si no está de acuerdo con los términos aquí expuestos, le rogamos no utilice nuestra aplicación.
          </Typography>

          <Typography variant="h5" sx={{ textAlign: 'justify' }}>
            1. Responsable del tratamiento de los datos
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            La entidad responsable del tratamiento de los datos recopilados a través de <strong>Mi Rifa App</strong> es:
          </Typography>

          <List sx={{ listStyleType: 'disc', ml: 6 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>RAUH Technologies Limitada</strong></ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Dirección: Carrera 14 #98-73 Oficina 101. Bogotá – 110221. Colombia.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Correo electrónico de contacto:
              <Link href="mailto:administration@rauhtech.com"> <strong>administration@rauhtech.com</strong></Link>
            </ListItem>
          </List>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            2. Datos personales que recopilamos
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            <strong>Mi Rifa App</strong> recopila datos personales de los usuarios con el propósito de gestionar su experiencia en la plataforma. Los datos que recopilamos incluyen:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3, textAlign: 'justify' }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Datos de identidad:</strong> Nombre y apellido.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Datos de contacto:</strong> Dirección de correo electrónico y número de teléfono.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Información de compra:</strong> En el caso de que adquiera una boleta, recopilaremos el nombre, apellido, correo electrónico y número de teléfono del comprador.</ListItem>
          </List>

          <Typography sx={{ mt: 1, ml: 3, textAlign: 'justify' }}>
            <strong>No recopilamos información sensible</strong> como datos de origen étnico, orientación sexual, religión o creencias políticas.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            3. Finalidad del tratamiento de los datos
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Utilizamos los datos recopilados para las siguientes finalidades:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Gestión de compras y participaciones:</strong> Procesamos la información necesaria para que usted pueda participar en las rifas organizadas a través de nuestra plataforma, así como para realizar el seguimiento de sus compras.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Comunicaciones:</strong> Utilizamos sus datos de contacto para enviarle notificaciones relacionadas con la confirmación de compras, recordatorios de pago, actualizaciones de las rifas y cualquier otra información relevante.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Publicidad y marketing:</strong> Implementamos servicios de publicidad para mostrar anuncios personalizados basados en su interacción con la aplicación. Estos servicios pueden recopilar ciertos datos para mostrar publicidad relevante.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Notificaciones Push:</strong> Para enviar notificaciones push a su dispositivo, recopilamos y utilizamos el token de su dispositivo.</ListItem>
          </List>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            4. Base jurídica del tratamiento de los datos
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            La recopilación y el tratamiento de sus datos personales se realizan sobre la base de las siguientes justificaciones legales:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Ejecución de un contrato:</strong> Tratamos sus datos personales para gestionar su participación en las rifas, así como para registrar y actualizar el estado de las boletas. Esta gestión es necesaria para la ejecución del contrato que usted celebra con nosotros al utilizar nuestra aplicación.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Consentimiento:</strong> En ciertos casos, como en la recepción de notificaciones push o el envío de comunicaciones publicitarias, trataremos sus datos sobre la base de su consentimiento explícito. Usted tiene derecho a retirar su consentimiento en cualquier momento.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Interés legítimo:</strong> También tratamos sus datos basándonos en nuestro interés legítimo para mejorar nuestros servicios y garantizar la seguridad de la aplicación.</ListItem>
          </List>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            5. Seguridad de los datos
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            En <strong>RAUH Technologies Limitada</strong>, implementamos medidas de seguridad robustas para proteger la información personal de los usuarios. Esto incluye el uso de protocolos avanzados de encriptación y tecnologías de seguridad que garantizan que los datos se almacenen de manera segura y sólo sean accesibles por personal autorizado. También contamos con un sistema de autenticación y gestión de sesiones que protege el acceso a la información sensible.
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Además, revisamos y actualizamos regularmente nuestras prácticas de seguridad para mantenernos al día con las mejores prácticas y proteger los datos contra accesos no autorizados, alteraciones o divulgaciones.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            6. Retención de datos
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Retendremos sus datos personales mientras sea necesario para los fines para los que fueron recopilados, incluyendo el cumplimiento de obligaciones legales, contables o informativas. De manera general, mantendremos su información indefinidamente a menos que usted solicite su eliminación, como se describe a continuación.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            7. Derechos del usuario
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            De acuerdo con la normativa aplicable, usted tiene los siguientes derechos sobre sus datos personales:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Derecho de acceso:</strong> Puede solicitar información sobre los datos que tenemos sobre usted.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Derecho de rectificación:</strong> Tiene el derecho a solicitar que corrijamos cualquier información inexacta o incompleta.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Derecho de supresión:</strong> Puede solicitar que eliminemos sus datos personales de nuestros sistemas en cualquier momento.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Derecho a la limitación del tratamiento:</strong> Puede solicitarnos que limitemos el tratamiento de sus datos en determinadas circunstancias.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Derecho a la portabilidad de los datos:</strong> Tiene el derecho de recibir una copia de sus datos en un formato estructurado, de uso común y lectura mecánica.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Derecho a oponerse:</strong> Puede oponerse al tratamiento de sus datos personales en algunos casos, como el tratamiento para fines de marketing directo.</ListItem>
          </List>

          <Typography sx={{ ml: 3, textAlign: 'justify' }}>
            Para ejercer cualquiera de estos derechos, puede contactarnos a través del correo electrónico
            <Link href="mailto:administration@rauhtech.com"> <strong>administration@rauhtech.com</strong> </Link>
            o accediendo a la URL habilitada en la aplicación para la eliminación de datos.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            8. Compartición de datos con terceros
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            No compartimos ni vendemos los datos personales de nuestros usuarios a terceros. La información que recopilamos es estrictamente utilizada para los fines descritos en esta política.
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            En cuanto a servicios externos, utilizamos:
          </Typography>

          <List sx={{ listStyleType: 'disc', pl: 4, ml: 3 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>AdMob:</strong> Un servicio de Google para mostrar anuncios dentro de la aplicación. Este servicio puede recopilar ciertos datos del dispositivo para personalizar la publicidad que se muestra.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Notificaciones push:</strong> Utilizamos su token de dispositivo para enviar notificaciones relacionadas con su actividad en la aplicación.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>Google Analytics:</strong> Para obtener métricas y analizar el uso de nuestra aplicación, siempre cumpliendo con las normativas de privacidad.</ListItem>
          </List>

          <Typography variant="h5" sx={{ mt: 3, textAlign: 'justify' }}>
            9. Transferencias internacionales de datos
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Todos los datos que recopilamos se almacenan y procesan en servidores ubicados en Estados Unidos de América, y nos aseguramos de que cualquier transferencia internacional de datos personales cumpla con las normativas de protección de datos aplicables, como el RGPD.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            10. Modificaciones a la política de privacidad
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Cualquier cambio será debidamente notificado a través de la aplicación y en nuestra página web. Le recomendamos que revise esta política periódicamente para estar al tanto de cualquier actualización.
          </Typography>

          <Typography variant="h5" sx={{ mt: 5, textAlign: 'justify' }}>
            11. Contacto
          </Typography>

          <Typography sx={{ mt: 2, ml: 3, textAlign: 'justify' }}>
            Si tiene alguna pregunta, duda o solicitud relacionada con esta política de privacidad o sobre cómo gestionamos sus datos personales, puede contactarnos a través de los siguientes medios:
          </Typography>

          <List sx={{ listStyleType: 'disc', ml: 6 }}>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}><strong>RAUH Technologies Limitada</strong></ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Dirección: Carrera 14 #98-73 Oficina 101. Bogotá – 110221. Colombia.</ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Correo electrónico de contacto:
              <Link href="mailto:administration@rauhtech.com"> <strong>administration@rauhtech.com</strong></Link>
            </ListItem>
            <ListItem sx={{ display: 'list-item', textAlign: 'justify' }}>Sitio web: <Link href="https://www.rauhtech.com"><strong>https://www.rauhtech.com</strong></Link></ListItem>
          </List>
        </Grid2>
      </Grid2>
    </Box >
  );
};

export default PoliticasPrivacidad;
