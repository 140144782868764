import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Icon } from '@mui/material';

const InfoListItem = ({ icono, titulo, descripcion }) => {
  return (
    <ListItem>
      <ListItemIcon>
        <Icon baseClassName="material-icons">{icono}</Icon>
      </ListItemIcon>
      <ListItemText primary={titulo} secondary={descripcion} />
    </ListItem>
  );
};

export default InfoListItem;
