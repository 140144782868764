import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const Carrusel = () => {
  const images = [
    '/assets/imgs/imagen-1.png',
    '/assets/imgs/imagen-2.png',
    '/assets/imgs/imagen-3.png'
  ];

  return (
    <Carousel showArrows={true} showThumbs={false} showStatus={false} autoPlay infiniteLoop>
      {images.map((src, index) => (
        <div key={index}>
          <img
            src={src}
            alt={`Slide ${index + 1}`}
            loading='lazy'
          />
        </div>
      ))}
    </Carousel>
  );
};

export default Carrusel;
